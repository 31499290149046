import { isStringEmpty } from '../../../util/stringAndMappingHelper';
import { ITouchTarget } from './ITouchTarget';
import { preambleTitleCharacterLimit, hintHeadlineCharacterLimit} from '../../../constants/componentConstants';
import { ImageValidator } from '../../../util/ImageValidator';


export interface IManagementCard {
    /**
     * Version. Note, If representation of Card changes, version can be used.
     */
    version?: number;
    /**
     * Free-formed image link
     */
    imageLink?: string;
    /**
     * Free-formed hint text
     */
    hintText?: string;
    /**
     * Free-formed header text
     */
    headerText?: string;
    /**
     * Free-formed title text
     */
    titleText?: string;
    /**
     * Free-formed button text
     */
    buttonText?: string;
    /**
     * List of touch targets tied with this card
     */
    touchTargets?: ITouchTarget[];
    /**
     * Free-formed image link for card background image curated for a Small Hub (Landscape) viewport profile
     */
    hubLandscapeSmallBackgroundImage?: string;
    /**
     * Free-formed image link for card background image curated for a Medium Hub (Landscape) viewport profile
     */
    hubLandscapeMediumBackgroundImage?: string;
    /**
     * Free-formed image link for card background image curated for a Large Hub (Landscape) viewport profile
     */
    hubLandscapeLargeBackgroundImage?: string;
    /**
     * Free-formed image link for card background image curated for an X Large Hub (Landscape) viewport profile
     */
    hubLandscapeXLargeBackgroundImage?: string;
    /**
     * Synthetic Utterance override if not using Hint Text
     */
    syntheticUtteranceOverrideText?: string;
    /**
     * Boolean indicating whether the content is synthetic utterance
     */
    isSyntheticUtterance?: boolean;
}

export const MANAGEMENT_CARD_VERSION: number = 1;

/* Card Utility Methods */

export function isCardEmpty(card: IManagementCard) {
    return isStringEmpty(card.imageLink)
            && isStringEmpty(card.titleText)
            && isStringEmpty(card.hintText)
            && isTouchTargetsEmpty(card.touchTargets)
            && isStringEmpty(card.hubLandscapeSmallBackgroundImage)
            && isStringEmpty(card.hubLandscapeMediumBackgroundImage)
            && isStringEmpty(card.hubLandscapeLargeBackgroundImage)
            && isStringEmpty(card.hubLandscapeXLargeBackgroundImage);
}

export async function isCardConfigured(card: IManagementCard, useTouchActionTemplate: boolean,
                                       overrideDefaultBackgroundImage: boolean, acknowledgedBackgroundImage: boolean,
                                       isCustomDomainIllustration: boolean, allowDvpSourceForImages: boolean) {
    if (isStringEmpty(card.titleText) || (card.titleText && card.titleText.length > preambleTitleCharacterLimit)) {
        return false;
    }
    const regex = /\$\{[^}]+\}/;
    const hasDynamicPlaceholder = card.hintText?.match(regex) ?? false;
    if (isStringEmpty(card.hintText) || (!hasDynamicPlaceholder && card.hintText && card.hintText.length > hintHeadlineCharacterLimit)) {
        return false;
    }
    if (useTouchActionTemplate) {
        if (card.isSyntheticUtterance) {
            if (isStringEmpty(card.syntheticUtteranceOverrideText)) {
                return false;
            }
        } else {
            if (isTouchTargetsEmpty(card.touchTargets)) {
                return false;
            }
        }
    }
    // for preconfigured domain illustration icons, only non-emptiness check is done
    if (!isCustomDomainIllustration && isStringEmpty(card.imageLink)) {
        return false;
    }
    try {
        // network check is needed for custom domain illustration
        if (isCustomDomainIllustration)
            await ImageValidator.isIconValid(card.imageLink, allowDvpSourceForImages);
        if (overrideDefaultBackgroundImage) {
            if (!acknowledgedBackgroundImage) {
                return false;
            }
            if (card.hubLandscapeSmallBackgroundImage)
                await ImageValidator.isBackgroundImageValid(card.hubLandscapeSmallBackgroundImage, allowDvpSourceForImages);
            if (card.hubLandscapeMediumBackgroundImage)
                await ImageValidator.isBackgroundImageValid(card.hubLandscapeMediumBackgroundImage, allowDvpSourceForImages);
            if (card.hubLandscapeLargeBackgroundImage)
                await ImageValidator.isBackgroundImageValid(card.hubLandscapeLargeBackgroundImage, allowDvpSourceForImages);
            if (card.hubLandscapeXLargeBackgroundImage)
                await ImageValidator.isBackgroundImageValid(card.hubLandscapeXLargeBackgroundImage, allowDvpSourceForImages);
        }
        return true;
    }
    catch {
        return false;
    }
}

export function isTouchTargetsEmpty(touchTargets?: ITouchTarget[]) {
    if (touchTargets) {
        for (const each of touchTargets) {
            if (isStringEmpty(each.payload) || isStringEmpty(each.targetAddress)) {
                return true;
            }
        }
        return false;
    }
    return true;
}
